table {
  @include font-feature-settings("kern", "liga", "tnum");
  border-collapse: collapse;
  margin: 0 auto 60px;
  table-layout: fixed;
  width: 95%;
}

th {
  font-weight: 600;
  padding: $small-spacing 0;
  text-align: left;
}

td {
  padding: $small-spacing 0;
}

tr,
td,
th {
  vertical-align: middle;
}
