.buy-credits-text {
    height: 80%;
}

.credit-panel {
    margin-top: 1em;
    margin-bottom: 1em;

    p {
        max-width: none;
    }

    span {
        display: block;
    }
}

.forfait-container {
    background: $boxBG;

    .type {
        i {
            font-size: 100px;
            padding-bottom: 10px;
        }
    }

    img {
        height: 250px;
    }
}

.forfait-table {
    .empty-cell {
        background-color: transparent;
    }

    .small {
        width: 5px;
    }

    .title {
        background-color: transparent;
        color: $base-font-color;
    }
}
