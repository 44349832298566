header{
  @include span-columns(2);
  text-align: center;
  position: relative;
  height: 350px;


  @include media($tablet) {
    @include span-columns(12);
    height: 190px;
  };

  @include media($desktop) {
    @include span-columns(12);
  };

  img{
    margin:em(5) 0;
    width: 135px;
    height: 162px;

    @include media($tablet) {
      position: absolute;
      left: 50%;
      margin-left: -60px;
    };
  };

  ul{
    width: 100%;
    text-align: center;
    background: $boxBG;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    padding: em(10) 0;
    @include media($tablet) {
      margin-top: em(80);
      display: inline-block;
    };
  };

  ul li{
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.9em;
    color: $heading;

    @include media($tablet) {
      display: inline-block;
      width: em(100);
    };

    @include media($desktop) {
      font-size: 1em;
      width: em(150);
    };
  };

  ul li:nth-of-type(3){

    @include media($tablet) {
      margin-right: 150px;
    };

  };

  ul li a{

    color: $heading;
    padding-bottom: em(3);

    &:hover{
      color: darken($heading, 15%);

    };
  };

  .fa-bars{
      display: block;
      display: none;
      @include media($tablet) {
        display: none;
      };
  };
};

.active{
  color: darken($heading, 15%);

};
