.joinUs{
  padding: em(20);
  @include media($tablet) {
    height: 350px;
    @include span-columns(6);
  };


  h2{
    color: $heading;
    font-size: 1.5em;
  };
  ul{
    font-size: 1em;
    color: $text;
  };
};


.contact-form{
  @include clearfix;
  padding: em(20);
  height: auto;
  @include media($tablet) {
    @include span-columns(6);
  };


  h2{
    color: $heading;
    font-size: 1.3em;
    line-height: 1.5em;
  };

  textarea{
    display: block;
    min-height: 150px;
    border: 1px solid $text;
  };

  button{
    float: right;
    margin: em(7) 0 0 0;
  };
};
