#coopReturn{
    display: block;
    text-align: center;
    text-decoration: underline;
    font-size: 1.1em;
    margin: 20px auto;
};


.formCatering{
    margin-top: em(20);
    background: $boxBG;
    @include span-columns(2);
    @include media($tablet) {
        @include span-columns(10);
        @include shift(1);
    };
    h2{
        color: $heading;
        padding: em(20) em(15);
        font-size: 1.8em;
    };
    form{
        margin: em(20) em(40);
    };
    label{
        color: $text;
    };
    textarea{
        height: 200px;
    };

    #floatRight{
        float: right;
        margin: 0 0 20px ;
    };
};
