.about-wrap{
	position: relative;

	h3{
	font-size: 1.1em;
	};

	p{
		max-width: 90%;
	@include media($tablet){
			max-width: 70%;
		};

	@include media($desktop){
			max-width: 75%;
		};
	};

	// #kidThinking{
	// 	display: none;

	// 	@include media($tablet){
	// 		display: block;
	// 		height: 300px;
	// 		float: right;
	// 		margin-bottom: -50px;
	// 	};
	// };

	#kidThinking{
		position: absolute;
		bottom: 0;
		display: none;


		@include media($tablet){
			display: block;
			right: 5px;
			width: 250px;
			padding-top: 50px;
		};

		@include media($desktop){
			right: 20px;
			width: 300px;
			padding-top: 0px;
		};
	};

    .expands {
        display: none;
    }

};
