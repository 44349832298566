.top-menu-wrapper{
    padding: em(10);
    @include media($tablet) {
        @include span-columns(10);
        @include shift(1);
        padding: 0px;
    };
    @include media($desktop) {
        @include span-columns(12);
        @include shift(0);

    };

    #leftContainer{
        @include span-columns(2);

        @include media($tablet){
            @include span-columns(6);
        };
        @include media($desktop){
            @include span-columns(4);
            height: 180px;
        };
    };

    #middleContainer{
        @include span-columns(2);

        @include media($tablet){
            @include span-columns(10);
            @include shift(1);
        };

        @include media($desktop){
            @include span-columns(4);
            @include shift(0);
            height: 180px;
        };
    };

    #rightContainer{
        @include span-columns(2);
        @include media($tablet){
            @include span-columns(6);
        };
        @include media($desktop){
            @include span-columns(4);
            height: 180px;
        };
    };
    .month-selector{
        text-align: center;
        a,h1{
            padding-bottom: 0px;
            color: $button;



            a:hover{
                color: lighten($button, 15%);
            };
        };

        h1{
            font-size: 25px;
        };
    };

    .menu-dl{
        position: relative;
        display: none;

        @include media($tablet) {
            display: block;
            margin-top: 125px;
            margin-bottom: 0px;
        };
        span{
            position: absolute;
            top: 7px;
            left: 40px;
            width: 150px;
        };

        @include media($tablet){
            float: left;
        };
    };

    .legend{
        color: $text;
        color: $text;
        background: $boxBG;
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
        padding: 15px 10px;
        margin-top: 35px;
        margin-bottom: 30px;
        @include media($tablet) {
            margin-bottom: 0px;
            width: 200px;
            float: right;
            font-weight: 600;
        };


        h2{
            display: block;
            font-weight: 500;
        };

        ul{
            font-size: 14px;
            font-weight: 400;
            display: block;
            width: 100%;
            li{
                position: relative;
                display: inline-block;
                @include media($tablet) {
                    display: block;
                };
            };
            span{
                display: inline-block;
                @include media($tablet) {
                    position: absolute;
                    left: 40px;
                };
            };
        };

        img{
            @include size(em(24));
            margin-left: em(10);
        };

    };

};

.box {
    background: $boxBG;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    @include clearfix;
    position: relative;
    margin-bottom: 20px;

    @include media($tablet) {
        font-size: 0.78em;
        margin-top: 2em;
        margin-bottom: 0;
        @include span-columns(10);
        @include shift(1);
    };

    @include media($desktop) {
        @include span-columns(2.4);
        @include omega(5n);
        @include shift(0);
        height: em(500);
    };

    &.box-small {
        @include media($desktop) {
            @include span-columns(2.4);
            @include omega(5n);
            @include shift(0);
            height: em(300);
        };


        .title{
            h2{
                font-size: 10pt;
                color: white;
            };
            span {
                font-size: 10pt;
            }
        }

        .overlayMenu{
            .orderedMeal{
                top: calc(50% - 0px);
                font-size: 0.6em;
                span{
                    font-size: 2.3em;
                };
            }
        }

        button{
        };
    }

    .overlayMenu{
        background: rgba($button, 0.8);
        color: rgba($primary, 0.8);
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;

        .orderedMeal{
            position: absolute;
            height: 100px;
            top: calc(50% - 50px);
            left: 0;
            right: 0;
            text-align: center;

            span{
                display: block;
                margin: 0 auto;
                font-size: 2.3em;
                font-weight: bold;
                text-align: center;
            };

            ul{
                display: block;
                margin: 0 auto;
                text-align: center;
                li{
                    font-size: 1.4em;
                    font-weight: 400;
                };

            };
        };

    };

    .title{
        background: $button;
        color: $primary;
        position: relative;
        font-size: 2em;
        border-radius: 3px 3px 0px 0px;


        h2{
            padding: em(5);
            margin-bottom: 13px;
        };

        span{
            position: absolute;
            right: em(7);
            top: em(1.5);
            @include media($desktop) {
                top: em(2.5);
                right: em(7);
            };
        };
        &.selected {
            background: $highlight;
        }
    };



    .float-left{
        float: left;
        margin-right: em(50);
        @include media($desktop) {
            float: none;
            margin-right: 0px;
        };
    };

    .meal-wrap{
        padding: 0px em(10);
        color: $heading;
        margin-bottom: 5px;
        @include media($tablet) {
            padding: em(10) em(10);
        };
        @include media($desktop) {
            padding: 0px em(10);
        };

        li.tips {
            list-style-type: none;
            label {
                display: inline-block;
            }
            input, span {
                vertical-align: middle;
            }
        }

        h3{
            @include media($tablet) {
                font-size: 1.5em;
            };
            @include media($desktop) {
                font-size: 1.2em;
                margin-bottom: 4px;
            };
        };
        ul{
            padding: 0px 0px 8px 0;
            font-size:0.9em;

            @include media($tablet) {
                padding: 0px 0px 0px 0;
            };
            @include media($tablet) {
                padding: 0px 0px 8px 0;
            };
            li{
                padding-bottom: 3px;
                list-style-type: none;
                list-style-position: inside;
                color: $heading;
                span{
                    color: $text;
                    font-weight: 500;
                    @include media($tablet) {
                        font-size: 1.3em;
                    };
                    @include media($desktop) {
                        font-size: 1em;
                    };
                };
            };
        };
        p{
            color: $text;
            font-weight: 500;
            @include media($tablet) {
                font-size: 1.3em;
            };
            @include media($desktop) {
                font-size: 1em;
            };
        };
    };

    button{
        position: absolute;
        bottom: em(10);
        right: em(10);
    };
};

.bottom-btn-container button{
    float: right;
    margin: em(50) 0px;


};

.mealSelectContainer {
    @include clearfix;
    margin-bottom: em(50);
};

#menuCheatMargin{
    margin-bottom: 80px;
};

.wrapper {
    position: relative;
    @include span-columns (2);
    height: 150px;
    background: $boxBG;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;


    @include media($tablet){
        @include span-columns(12);
    };

    h1{
        font-size: 1.4em;
        color: $button;
        position: absolute;
        top: 10px;
        left: 10px;
    };
}

.internal {
    display: inline-block;
    width: 100px;
    height: 100px;
    margin: 25px 40px 0;

}

.noel {
    background-image: url("/img/theme/noel.png") !important;
    background-repeat: repeat-x !important;
    background-size: 50% !important;
    background-position: right center; }

    .noel h2 {
        text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black !important; }

        .noel span {
            text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black !important; }

            .halloween {
                background-image: url("/img/theme/halloween.png") !important;
                background-repeat: repeat-x !important;
                background-size: 27% !important;
                background-position: right center; }
                @media screen and (min-width: 1090px) {
                    .halloween {
                        background-size: 80% !important; } }

                        .halloween h2 {
                            text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black !important; }

                            .halloween span {
                                text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black !important; }

                                .egg {
                                    background-image: url("/img/theme/egg.png") !important;
                                    background-repeat: repeat-x !important;
                                    background-size: contain !important;
                                    background-position: right center; }
                                    @media screen and (min-width: 1090px) {
                                        .egg {
                                            background-size: contain !important; } }

                                            .egg h2 {
                                                text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black !important; }

                                                .egg span {
                                                    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black !important; }

                                                    .heart {
                                                        background-image: url("/img/theme/heart.png") !important;
                                                        background-repeat: repeat-x !important;
                                                        background-size: contain !important;
                                                        background-position: right center; }
                                                        @media screen and (min-width: 1090px) {
                                                            .heart {
                                                                background-size: contain !important; } }

                                                                .heart h2 {
                                                                    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black !important; }

                                                                    .heart span {
                                                                        text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black !important; }
