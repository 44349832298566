.outer-container.cart-container {
    text-align: right;
    margin-bottom: 20px;
    position: relative;

    #cartBar {
        position: relative;
        -webkit-font-smoothing: antialiased;
        background-color: $button;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        transition: all 0.2s ease-in-out;
        border: none;
        color: $primary;
        cursor: pointer;
        display: inline-block;
        font-family: $base-font-family;
        font-weight: 600;
        padding: 1em 1.2em;
        text-decoration: none;
        user-select: none;
        vertical-align: middle;
        white-space: nowrap;

        i {
            float: left;
            margin-right: 10px;
        }

        ul {
            font-size: 10pt;
        }
        h5 {
            margin: 0;
        }
    }

    @include media($tablet) {
        #cartBar {
            margin-top: 0px;
        }
    }

    @include media($desktop) {
        #cartBar {
            margin-top: -45px;
        }
    }
}
