.reviewPanel{
    @include span-columns(2);
    @include clearfix;
    margin-bottom: em(20);
    background: $boxBG;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    border-radius: 3px;

    h2{
        color: $heading;
        font-size: 1.5em;
        padding: em(10) 0 0 em(10);
        @include media($desktop) {
            font-size: 2.1em;
        }
    };

    p{
        color: $text;
        padding: 20px;
    };

    table{
        color: $text;
        &.review-table {
            margin: 0 0;
            margin-left: 20px;
            max-width: 100%;
            @include media($desktop) {
                max-width: 60%;
            }
            tr {
                td {
                    padding: 0px 0px;
                    text-align: right;
                    &:last-child {
                        padding-left: 20px;
                        width:130px;
                    }
                }
                &:last-child > td {
                    padding: 20px 0px;
                    padding-left: 20px;
                }
            }
        }
    };


    .line{
        border-bottom: 1px solid $heading;
    };

    .acceptCheckbox{
        color: $text;
        padding-left: em(20);
        margin-bottom: em(20);
    };

    .floatRightBtn{
        float: right;
        margin: 0 em(20) em(20) 0;
    };
};
