.menuDaycamp {
    margin-top: em(20);
    background: $boxBG;
    @include span-columns(2);

    h2 {
        color: $heading;
        padding: em(20) em(15);
        padding-bottom: 10px;
        font-size: 1.8em;
        margin: 0;
    }

    form {
        margin: em(20) em(40);
    }

    label {
        color: $text;
    }

    textarea {
        height: 200px;
    }

    .middleContainer {
        text-align: center;
    }
}
