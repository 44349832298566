h1{
  font-size: 2em;
  text-align: center;
  padding-bottom: em(20);
  color: $heading;
};

.about-wrap{
  background: $boxBG;
  color: $text;
  border-radius: 5px;
  padding: em(50) em(30);
  margin: em(40) 0;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);

  @include media($tablet) {
    @include span-columns(12);

  };

  h2{
    font-size: 1.3em;
    display: inline-block;
    padding-bottom: em(2);
    color: $heading;
  };

  h3 {
    &.centered {
      width: 100%;
      text-align:center;
    }
  };

  p{
    font-weight: 400;
    padding: 0 0 em(10) em(5);
  };
};
