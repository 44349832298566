.reset-wrap{
	@include span-columns(2);
	background: $boxBG;
  	box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  	margin: em(10) 0;
  	@include clearfix;

	@include media($tablet) {
		@include span-columns(6);
		@include shift(3);
	};

	form{
		padding: 20px;
	};
	button{
		float: right;
		margin: 10px 0 20px;
	};
};