.paymentPanel{
    @include span-columns(2);
    @include clearfix;
    margin-bottom: em(20);
    background: $boxBG;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    border-radius: 3px;

    h2{
        color: $heading;
        font-size: 1.5em;
        padding: em(10) 0 0 em(10);
        @include media($desktop) {
            font-size: 2.1em;
        }
    };

    .billingAddresForm{
        padding: em(10);
        padding-bottom: 55px;
    }

    .cancelForm{
        display: inline-block;
    }

    .input{
        display: inline-block;
        width: 45%;

        select{
            width: 100%;
        }
    }

    .input-right{
        float: right;
    }

    @media screen and (max-width: 715px) {
        .input {
            display: block;
            width: 100%;
        }

        .billingAddresForm{
            padding-bottom: 135px;
        }
    }

    .floatRightContainer{
        float: right;
    }
};
