
.blocks, .first-row-blocks{
  background: $boxBG;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  margin: em(10) 0;
  position: relative;
};

.blocks{
  height: 250px;
};

.first-row-blocks{
  height: 400px;

};

.slick-prev:before,
.slick-next:before {
    color: #6bcef8;
}

.slick-prev {
    left: 5px;
    z-index: 1;
}

[dir='rtl'] .slick-prev {
    right: 5px;
}

.slick-next {
    right: 5px;
}

[dir='rtl'] .slick-next {
    left: 5px;
}

.slick-dots {
    bottom: 0px;
    padding: 15px;
}

.slick-dots li {
    margin: 0;
    padding-top:10px;
    padding-bottom: 10px;
}

.slick-dots li.slick-active button:before {
    color: #6bcef8;
}

.slick-img {
    text-align:center;
    margin-right: auto;
    margin-left:auto;
    padding-top:20px;
    outline:none;
}

.slider-block{
  position: relative;
  display: block;
  overflow: hidden;
  @include media($tablet) {

    @include span-columns(8);
   };

    .slide-image{
        height: 400px;
        width: 100%;
        margin: 0 auto;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        .absoluteSlider{
          position: absolute;
          top: 20px;
          left: 20px;
          h2{
              display: inline-block;
              text-align: left;
              color: $primary;
              background: rgba($button, 0.6);
              font-size: 1em;
              padding: 10px;
              margin: 5px;
              @include media($tablet) {
                 font-size: 1.4em;
              };
          };
        };
    };
};


.connexion-block{

  @include span-columns(2);
  position: relative;
  @include clearfix;

  @include media($tablet) {
    @include span-columns(4);
};

  .login{
      height: 178px;

  };
  .userWelcome{
      text-align: center;
      height: 200px;
      #username{
          font-size: 1.3em;
          color: $heading;
          padding-top: em(10);
      };
      ul{
          color: $text;
          margin-bottom:em(20);
          i{
              padding-left: em(80);
          };
      };
      button{
          display: block;
          width: 200px;
          line-height: 0;
          margin: 20px auto;
      };
  };

  form{
    margin: em(10) em(20);
    @include clearfix;

    label{
      margin-top: 5px;
      margin-right: 10px;
      display: inline-block;
    };

    .field{
    height: em(35);
    margin: 0;
    width: 100%;
    };

    .submit-btn{
      float: right;
      height: 35px;
      line-height: 0;
      margin-top: 10px;
    };
  };

  .sign-up a{
        position: absolute;
        top: em(160);
        right: em(160);
        text-decoration: underline;
        cursor: pointer;
        @include media($tablet) {
            right: em(150);

        };
        @include media($desktop) {
            right: em(170);

    };
  };

  hr{
    position: absolute;
    width: 91%;
    top: 180px;
    left: 15px;
  };

  .fb-text{
    padding: em(15) em(20);
    color: $text;

    h2{
      @include media($tablet) {
        font-size: 1.0em;
      };
      @include media($desktop) {
        font-size: 1.1em;
      };
    };

    p{
      font-weight: 200;
      font-size: 1em;
      @include media($tablet) {
        font-size: 0.9em;
      };
      @include media($desktop) {
        font-size: 1em;
      };
    };
    };
    .img-container{
      position: absolute;
      bottom: em(5);
      right: em(10);

      img{
        width: 140px;
        height: auto;
        @include media($tablet) {
          width: 130px;
        };
        @include media($desktop) {
          width:140px;
        };
      };
    };

  };



.menu-block{
  overflow: hidden;
  position: relative;
  text-align: center;

  @include media($tablet) {
    @include span-columns(4);
  };
  img{
      @include size(180px);
      position: absolute;
      left: 10px;
      bottom:5px;
      @include media($tablet) {
          @include size(150px);
          left: 10px;
          bottom: 5px;
      };
      @include media($desktop) {
          @include size(180px);
          left: 10px;
          bottom: 5px;
      };
  };

  h1{
    position: absolute;
    right: 15px;
    top: 10px;
    text-align: right;
    color: $heading;
    font-size: 1.4em;
    max-width: 55%;
    display: inline-block;

    @include media($tablet) {
        font-size: 1.5em;
        max-width: 70%;
    };

    @include media($desktop) {
        font-size: 1.7em;
        max-width: 60%;
    };
  };
  button{
    position: absolute;
    right: em(20);
    bottom: em(20);
    @include media($tablet) {
        font-size: 12px;
    };
    @include media($desktop) {
        font-size: 16px;
    };
  };

};

.traiteur-block{
  @include clearfix;
  overflow: hidden;
  position: relative;
  @include media($tablet) {
    @include span-columns(4);
  };

  img{
      @include size(230px);
      position: absolute;
      left: -20px;
      bottom:0px;
      @include media($tablet) {
          @include size(180px);
          left: -40px;
          bottom: 0px;
      };
      @include media($desktop) {
          @include size(230px);
          left: -25px;
          bottom: 0px;
      };
  };

  h1{
    position: absolute;
    right: 15px;
    top: 10px;
    text-align: right;
    color: $heading;
    font-size: 1.4em;
    max-width: 50%;
    display: inline-block;

    @include media($tablet) {
        font-size: 1.5em;
        max-width: 60%;
    };

    @include media($desktop) {
        font-size: 1.7em;
        max-width: 60%;
    };
  };

  ul{
      position: absolute;
      top: 90px;
      right: 10px;
      color: $text;
      list-style-type: disc;
      @include media($tablet) {
          font-size: 0.9em;
          top: 100px;
      };
      @include media($desktop) {
        font-size: 1em;
        top: 90px;
      };
  };

  button{
    position: absolute;
    right: em(20);
    bottom: em(20);
    @include media($tablet) {
        font-size: 12px;
    };
    @include media($desktop) {
        font-size: 16px;
    };
  };



  .img-container{
    text-align: center;
    img{
      @include size(100px);
      margin: 0 em(30);

      @include media($tablet) {
        @include size(70px);
        margin: 0 em(20);
      };
      @include media($desktop) {
        @include size(100px);
        margin: 0 em(20);
      };
    };
  };
};

.partner-block{
  position: relative;
  h1{
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 1.4em;


    @include media($tablet) {
        font-size: 1.5em;

    };

    @include media($desktop) {
        font-size: 1.7em;

    };
  };

  @include media($tablet) {
    @include span-columns(4);
  };

  .imgBox{
    width: 200px;
    height: 200px;
    margin: 23px auto;


    img{
       margin: 0 auto;
    };
  };

};

.image-slider {
    outline: none;
    position: relative;
    width: 100%; /* for IE 6 */
}
