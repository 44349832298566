// Typography
$base-font-family: open sans;
$heading-font-family: $base-font-family;

// Font Sizes
$base-font-size: 1em;

// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 3px;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

// Colors
$dark-gray: #333;
$medium-gray: #999;
$light-gray: #ddd;

$gray: #292c2f;
$beige: #fdffe6;
$button: #e86121;
$primary: #fcfcfc;
$heading: #6bcef8;
$boxBG: rgba(#eeeeee, 0.9);
$text: black;
$red: red;
$dark-blue: #337ab7;
$dark-red: rgba(239, 11, 11, 0.71);
$highlight: #32b1c4;

// Font Colors
$base-background-color: #fff;
$base-font-color: $dark-gray;
$action-color: $button;

// Border
$base-border-color: $light-gray;
$base-border: 1px solid $base-border-color;

// Forms
$form-box-shadow: inset 0 1px 3px rgba($heading, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($action-color, $lightness: 5%, $alpha: -0.3);
