footer{
  text-align: center;
  height: 50px;
  background: $boxBG;
  color: $text;
  margin-top: em(10);

  .copyright{
    padding: em(13) 0 0px;
    font-size: 10px;
    @include media($tablet) {
        font-size: 16px;
    };
    a{
        color: $heading;
    };
  };

};
