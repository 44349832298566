.orderReviewPanel{
    @include span-columns(2);
    @include clearfix;
    margin-bottom: em(20);
    background: $boxBG;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    border-radius: 3px;

    @include media($tablet) {
        @include span-columns(6);
        @include omega(2n);
    };

    h2{
        color: $heading;
        font-size: 2.1em;
        padding: em(10) 0 0 em(10);
    };

    p{
        color: $text;
        padding: 20px;
    };

    table{
        color: $text;
    };


    .line{
        border-bottom: 1px solid $heading;
    };

};

.newDateSelect{
    display: block;
    select{
        display: inline-block;
        width: 49%;

    };
};

.newMeal{
    display:block;
    select{
        width:100%;
    };
};
