.profile-container, .children-container, .modify-profile-container, .forfait-container {
  margin: em(10) 0;
  background: $boxBG;
  color: $text;
  padding: em(15);
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  position: relative;

  .settings-link {
    color: $text;
    margin-left: em(10);
    cursor: pointer;

  };

  span{
    font-weight: 300;
    color: $text;
  };
  form{
    padding-bottom: em(10);
  };
  h2{
    font-size: 1.5em;
    color: $heading;
  };
  ul{

    li{
      padding: em(7) 0;
      color: $text;
      font-weight: 600;
    };
  };
};

.step-img {
  display:none;
}

.forfait-table {
  // max-width: 700px;
  border-collapse: separate;
  border-spacing: 5px;
  margin: 0px;
}
.forfait-table th{
  text-align: center;
  border-radius: 5px;
  background-color: #e86121;
  color:white;
}
.forfait-table td{
  text-align: center;
  border-radius: 5px;
  background-color: #0390f4;
  color:white;
}

.forfait-container {
  background-color: white;
  float: left;
  display: block;
  margin-left: 0;
  margin-right: 22px;
  width: 100%;
  position: relative;
  text-align: center;
}
  .forfait-container p {
    width:100%;
  }
  .forfait-container:last-child {
    margin-right: 0;
}
  @media screen and (min-width: 715px) {
    .step-img {
      display:block;
    }
    .forfait-container {
      float: left;
      display: block;
      width: 48.9372%;
      margin-left:0%;
    }
    .forfait-container:last-child {
        margin-right: 0;
    }
}
  .forfait-container ul li:last-child {
    margin-bottom: 40px;
}
  .forfait-container .order-btn {
    position: absolute;
    bottom: 20px;
    right: 20px;
}

.nameContainer{
    display: inline-block;
    width: 49%
 };
.birthdateSelect{
    display: block;
    @include clearfix;
    select{
        display: inline-block;
        width: 32%;

    };
};

.profile-container{
  @include span-columns(2);
  @include media($tablet) {
    height: 335px;
    @include span-columns(7);
  };
};

.modify-profile-container{
  @include span-columns(2);
  @include media($tablet) {
    height: 335px;
    @include span-columns(5);
  };

.modify-btn{
  display: block;
  margin: 25.3px auto;
  width: 250px;
};
};

.children-container{
  @include span-columns(2);
  position: relative;

  @include media($tablet) {
    height: 450px;
    @include span-columns(4);
    @include omega(3n);
  };

  ul li:last-child{
    margin-bottom: 40px;
  };
  .order-btn{
    position: absolute;
    bottom: 20px;
    right: 20px;
  };

};
#modalContainer{
  display: none;
  z-index: 1;
  height: 100%;
  width: 100%;
  @include position(fixed, 0);
};

#overlay{
  display: none;
  background: rgba($text, 0.9);
  @include position(fixed, 0);
  z-index: 10;
};

.modal{
  @include clearfix;
  position: relative;
  display:none;
  background: $boxBG;
  color: $text;
  background: $boxBG;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  line-height: 2em;
  max-width: 450px;
  min-width: 300px;
  font-weight: 300;
  padding: em(20);
  margin: em(20) auto;
  z-index: 15;

  @include media($tablet) {
    min-width: 440px;
    margin: em(80) auto;

  };


  .head{
    padding-bottom: 3px;
    font-size: 1.2em;
    color: $heading;
    @include media($tablet) {
    font-size: 1.5em;
    };
  };

  .close{
    position: absolute;
    top: 3px;
    right: 10px;
    color: $button;
    @include media($tablet) {
    font-size: 1.3em;
    };
  };

  label{
    font-weight: 400;
    color: $text;
  };

  form{
    color: $text;

  };

  button{
    float: right;
    margin: em(5) 0 0 em(10);
  };

  p{
      font-weight: 400;
  };
};
