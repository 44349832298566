#{$all-button-inputs},
button {
  @include appearance(none);
  -webkit-font-smoothing: antialiased;
  background-color: $button;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.2s ease-in-out;
  border: none;
  color: $primary;
  cursor: pointer;
  display: inline-block;
  font-family: $base-font-family;
  font-size: $base-font-size;
  font-weight: 600;
  line-height: 0.5;
  padding: 1em 1.2em;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;


  &:hover,
  &:focus {
    box-shadow: 0 2px 4px rgba(0,0,0,0.19), 0 3px 3px rgba(0,0,0,0.23);
  }
  &:active {
    background-color: darken($button, 5%);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.selected {
    background-color: $highlight;
    &:active {
      background-color: darken($highlight, 5%);
    }
  }
}
