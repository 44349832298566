/************ General Page Elements */
*{
  box-sizing: border-box;
  margin: 0;
  border-radius: 3px;
  outline: 0;
};

body{
    background: url("/img/cloudBG.png") fixed;

};

html,body{
  height: 100%;
};

.page-wrap{
  min-height: 100%;
  margin-bottom: -60px;
};

.page-wrap:after {
  content: "";
  display: block;
}
.site-footer, .page-wrap:after {
  height: 50px;
}

.row-wrapper {
  @include row();
}
.outer-container {
  @include outer-container;
}

.slick-arrow , .slick-dots button{
    box-shadow: none;

    &:hover{
        box-shadow: none;
    };
};

#marginBottomCheat{
    margin-bottom: em(40);
};

.alertBoxError{
    background: $dark-red;
    color: $primary;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    line-height: 2em;
    line-height: 2em;
    transition: 0.3s ease-in-out;
    span{
        padding: 0 5px 0 10px;
        font-weight: bold;
    };
};

.alertBoxSucces{
    background: darken($heading, 15%);
    color: $primary;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    line-height: 2em;
    transition: 0.3s ease-in-out;
    span{
        padding: 0 5px 0 10px;
        font-weight: bold;
    }
};

.alertBoxWarning{
    background: $button;
    color: $primary;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    line-height: 2em;
    transition: 0.3s ease-in-out;
    span{
        padding: 0 5px 0 10px;
        font-weight: bold;
    }
};

.alertBoxInformation{
    background: $dark-blue;
    color: $primary;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    line-height: 2em;
    transition: 0.3s ease-in-out;
    span{
        padding: 0 5px 0 10px;
        font-weight: bold;
    }
};

.alerts {
    span {
        margin-top: em(10);
        &:first-child {
            margin: 0;
        };
        &:last-child {
            margin: em(10) 0 50px;
        };
    };
};

#cguModal{
  h1{
    font-size: 1.5em;
    color: $text;
  };
  h2{
    font-size: 1.3em;
  };
};

#modalContainer{
  h2{
    color: $button;
  };
};
