.cartPanel{
    @include span-columns(2);
    @include clearfix;
    margin-bottom: em(20);
    background: $boxBG;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    border-radius: 3px;

    h2{
        color: $heading;
        font-size: 2.1em;
        padding: em(10) 0 0 em(10);
    };

    p{
        color: $text;
        padding: 20px;
    };

    table{
        color: $text;
    };


    .line{
        border-bottom: 1px solid $heading;
    };

    .acceptCheckbox{
        color: $text;
        padding-left: em(20);
        margin-bottom: em(20);
    };

    .floatRightBtn{
        float: right;
        margin: 0 em(20) em(20) 0;
    };

    .cgu{
        text-align: right;
        padding-right: 2%;
        font-weight :600
    };
};
.payment-logo  {
    float: right;
    height: 20px;
    margin-right: 10px;

    @include media($desktop){
        float: right;
        height: 60px;
        margin-right: 10px;
    }
}
